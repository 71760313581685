import Types from "./types";
import { CatalogGatewayService } from "../../../service/catalog-gateway";

const setBuyCarsHeaderData = data => ({
    type: Types.SET_BUY_CARS,
    data
});

const fetchBuyCarsHeaderData = (cityId) => dispatch => {
    return new Promise((resolve, reject) => {
        const modifiedCityId = cityId ?? "";
        CatalogGatewayService.fetchHeaderBuyCars(modifiedCityId).then(response => {
            const { data: responseData } = response;
            const { data } = responseData || {};
            dispatch(setBuyCarsHeaderData(data));
            resolve(response);
        })
            .catch(error => {
                reject(error);
            });
    });
};

const updateSSRStatus = (isSSR) => ({
    type: Types.UPDATE_SSR_STATUS,
    isSSR
});

export {
    updateSSRStatus,
    fetchBuyCarsHeaderData
};
