import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { appUrl } from "../../../constants/url-constants";
import { newCarLinks } from "../../../constants/new-cars-constants";
import Accordion from "../../shared/accordion";

const  NewCarsLinks = ({
    onClose
}) => {
    const [rightArraowVisible, setRightArrowVisible] = useState(null);
    const [downArrowIconVisible, setDownArrowIconVisible] = useState(null);
    const [listHeading, setListHeading] = useState("");

    useEffect(() => {
        import(/* webpackChunkName: "seo-arrow" */ "../../shared/arrow").then(comp => {

            setRightArrowVisible(<comp.default
                rotateBy={-90}
                grey={true}
            />);

            setDownArrowIconVisible(<comp.default
                rotateBy={-180}
                grey={true}
            />);
        });
    }, [onClose]);

    const onClickHandler = ({item, index, onClick}) => {
        if (listHeading === item.heading) {
            setListHeading("");
        } else {
            setListHeading(item.heading);
        }
        onClick({ index });
    };
    return (
        <React.Fragment>
            <div styleName={"styles.bodyWrapper"}>
                <div styleName={"styles.listWrapper"}>
                    <a href={`${appUrl()}/new-cars/`} target="_blank">
                        <h3 styleName={"styles.cardTitle"}>
                            Explore New Cars
                            {rightArraowVisible && rightArraowVisible}
                        </h3>
                    </a>
                </div>
                <div styleName={"styles.listWrapper"}>
                    <a href={`${appUrl()}/new-cars/compare-cars/`} target="_blank">
                        <h3 styleName={"styles.cardTitle"} >
                            Compare
                            {rightArraowVisible && rightArraowVisible}
                        </h3>
                    </a>
                </div>
                <Accordion totalItems={newCarLinks.length}>
                    {({
                        onClick
                    }) => (
                        newCarLinks.map((item, index) => (
                            <div styleName={"styles.listWrapper"} key={index}>
                                <Accordion.Content>
                                    <Accordion.Heading>
                                        <h3 styleName={"styles.cardTitle"} onClick={() => onClickHandler({item, index, onClick})}>
                                            {item.heading}
                                            {item.heading !== listHeading ? (rightArraowVisible && rightArraowVisible) : (downArrowIconVisible && downArrowIconVisible)}
                                        </h3>
                                    </Accordion.Heading>
                                    <Accordion.Body>
                                        {item.links && (
                                            <ul styleName={`${listHeading === item.heading ? "styles.collapse" : "styles.collapse styles.hide"}`}>
                                                {
                                                    item.links.map((link) => (
                                                        <li key={link.title}>
                                                            <a href={`${appUrl()}/${link.seoLink}`} target="_blank">
                                                                {link.title}
                                                            </a>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        )}
                                    </Accordion.Body>
                                </Accordion.Content>
                            </div>
                        ))
                    )}
                </Accordion>
            </div>

        </React.Fragment>
    );
};

NewCarsLinks.propTypes = {
    onClose: PropTypes.func
};

export default NewCarsLinks;
