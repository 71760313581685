/* eslint-disable max-statements */
import React, { useState } from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import Smartlook from "smartlook-client";
import LazyImage from "../../shared/lazy-image";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import Button from "../../shared/button";
import { sendCustomGaEvents } from "../../../tracking";
import { hamburgerLogin } from "./tracking";
import { NUMBER, USER_LOGIN_MEDIUM, USER_LOGIN_STATUS } from "../../../constants/app-constants";
import { ga4Signout } from "../../../constants/tracking-constants/ga4-ecommerce-event";
import { statsigInitialization } from "../../../utils/statsig-ab-utils/initialize-user-csr";
import { ga4Signin } from "../../../constants/tracking-constants/ga4-ecommerce-event";

const LoginPanelCta = ({
    isLoggedIn,
    logoutIcon,
    // mobile,
    logoutUser,
    otp,
    phoneNumber,
    isLoading,
    setIsLoading,
    errorMessage,
    onMobileNumberChange,
    getOtpHandler,
    onOtpVerify,
    onOtpChange,
    resendOTPHandler,
    setOtp,
    setIsAutoFilledOTP,
    showOtpField,
    saveUserConsentConnect,
    resetTruecallerDetailsConnect,
    setIsTrueCallerConnect,
    updateStatsigConfigConnect,
    userCityId,
    gaId,
    updateStatsigTimeStampConnect,
    setShowOtpField,
    updateRecommendationTransferStatusConnect
}) => {
    const [showLoginScreen, setShowLoginScreen] = useState(false);
    const [LoginAuthenticatorWithTruecallerComp, setLoginAuthenticatorWithTruecallerComp] = useState(null);

    const handleLogout = async () => {
        logoutUser();
        updateRecommendationTransferStatusConnect(false);
        setShowLoginScreen(false);
        if (window.smartlook) {
            Smartlook.anonymize();
        }
        await yieldToMain();
        sendCustomGaEvents({
            ...ga4Signout,
            // user_id: mobile,
            user_status: USER_LOGIN_STATUS.LOGGED_OUT,
            login_type: USER_LOGIN_MEDIUM.NONE
        });
    };

    const [allowWhatsapp, setAllowWhatsapp] = useState(true);

    const onWhatsappChange = (e) => {
        const { target: { checked } } = e;
        setAllowWhatsapp(checked);
    };

    const saveConsent = () => {
        const params = {
            chat: allowWhatsapp
        };
        saveUserConsentConnect(phoneNumber, params);
    };

    const onBack = () => {
        setShowOtpField(false);
        setShowLoginScreen(false);
    };

    const onTruecallerSuccess = () => {
        setIsTrueCallerConnect(true);
        onBack();
    };

    const otpVerificationHandler = async () => {
        if (otp && otp.length === NUMBER.FOUR) {
            onOtpVerify().then(async () => {
                saveConsent();
                updateStatsigConfigConnect({ statsigInitialized: true });
                await statsigInitialization({ city: userCityId, clientId: gaId, updateUser: true });
                updateStatsigTimeStampConnect();
                onBack();
                await yieldToMain();
                sendCustomGaEvents({
                    ...ga4Signin,
                    // user_id: phoneNumber,
                    user_status: USER_LOGIN_STATUS.LOGGED_IN,
                    login_type: USER_LOGIN_MEDIUM.PHONE
                });
            });
        }
    };

    const importLoginAuthenticatorWithTrueCaller = () => {
        if (LoginAuthenticatorWithTruecallerComp === null) {
            import(/* webpackChunkName: "login-authenticator-with-truecaller" */ "../login-authenticator-with-truecaller").then((comp) => {
                setLoginAuthenticatorWithTruecallerComp(() => comp.default);
                setIsLoading(false);
                setShowLoginScreen(true);
            });
        } else {
            setIsLoading(false);
            setShowLoginScreen(true);
        }
    };

    const handleLoginSignup = async () => {
        await yieldToMain();
        setIsLoading(true);
        importLoginAuthenticatorWithTrueCaller();
        await yieldToMain();
        sendCustomGaEvents(hamburgerLogin);
    };

    if (isLoggedIn) {
        return (
            <div onClick={handleLogout} styleName={"styles.secondaryListStyle"}>
                <span>
                    <LazyImage src={logoutIcon} alt="Logout" width={24} height={24} />
                Logout
                </span>
            </div>
        );
    }

    if (showLoginScreen) {
        return (
            <React.Fragment>
                <LoginAuthenticatorWithTruecallerComp
                    showWhatsappConsent={true}
                    allowWhatsapp={allowWhatsapp}
                    onWhatsappChange={onWhatsappChange}
                    otp={otp}
                    onClose={onBack}
                    onBack={() => { setShowOtpField(false); }}
                    mobile={phoneNumber}
                    onChange={onMobileNumberChange}
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    onOtpChange={onOtpChange}
                    onOtpVerify={otpVerificationHandler}
                    showOtpField={showOtpField}
                    onResendClick={resendOTPHandler}
                    setOtp={setOtp}
                    setIsAutoFilledOTP={setIsAutoFilledOTP}
                    getOtpHandler={() => { getOtpHandler(phoneNumber); }}
                    setShowOtpField={setShowOtpField}
                    onTruecallerSuccess={onTruecallerSuccess}
                    resetTruecallerDetailsConnect={resetTruecallerDetailsConnect}
                />
            </React.Fragment>
        );
    }

    return (
        <div styleName={"styles.loginWrapperModel"}>
            <h3>Login with your phone number</h3>
            <p>Manage your journey with CARS24</p>
            <Button
                text={"LOGIN/SIGN UP"}
                onClick={handleLoginSignup}
                isLoading={isLoading}
            />
        </div>
    );
};

LoginPanelCta.propTypes = {
    isLoggedIn: PropTypes.bool,
    logoutIcon: PropTypes.any,
    mobile: PropTypes.string,
    logoutUser: PropTypes.func,
    otp: PropTypes.string,
    phoneNumber: PropTypes.string,
    isLoading: PropTypes.bool,
    errorMessage: PropTypes.string,
    onMobileNumberChange: PropTypes.func,
    getOtpHandler: PropTypes.func,
    onOtpVerify: PropTypes.func,
    onOtpChange: PropTypes.func,
    resendOTPHandler: PropTypes.func,
    setOtp: PropTypes.func,
    setIsAutoFilledOTP: PropTypes.func,
    showOtpField: PropTypes.bool,
    saveUserConsentConnect: PropTypes.func,
    resetTruecallerDetailsConnect: PropTypes.func,
    setIsTrueCallerConnect: PropTypes.func,
    updateStatsigConfigConnect: PropTypes.func,
    userCityId: PropTypes.string,
    gaId: PropTypes.string,
    updateStatsigTimeStampConnect: PropTypes.func,
    setShowOtpField: PropTypes.func,
    setIsLoading: PropTypes.func,
    updateRecommendationTransferStatusConnect: PropTypes.func
};

export default LoginPanelCta;

