export const TYPES = {
    POPULAR_CAR_LIST_BUY: "POPULAR_CAR_LIST_BUY",
    POPULAR_CAR_LIST_SELL: "POPULAR_CAR_LIST_SELL",
    POPULAR_CAR_LIST_RESOURCES: "POPULAR_CAR_LIST_RESOURCES",
    CF_SEO_LINKS: "CF_SEO_LINKS",
    NEW_CAR_LINKS: "NEW_CAR_LINKS",
    CAR_SERVICES: "CAR_SERVICES"
};

export const PRIMARY_LINK_HEADER_DATA = {
    [TYPES.POPULAR_CAR_LIST_BUY]: {
        icon: "BuyCarIcon",
        title: "Buy Used Car"
    },
    [TYPES.POPULAR_CAR_LIST_SELL]: {
        icon: "SellCarIcon",
        title: "Sell Used Car"
    },
    [TYPES.POPULAR_CAR_LIST_RESOURCES]: {
        icon: "ResourceIcon",
        title: "Resources"
    },
    [TYPES.CF_SEO_LINKS]: {
        icon: "CarLoanIcon",
        title: "Used Car Finance"
    },
    [TYPES.NEW_CAR_LINKS]: {
        icon: "NewCars",
        title: "New Cars"
    },
    [TYPES.CAR_SERVICES]: {
        icon: "BuyCarIcon",
        title: "Car Services"
    }
};

export const PRIMARY_LINKS = [
    TYPES.POPULAR_CAR_LIST_BUY,
    TYPES.POPULAR_CAR_LIST_SELL,
    TYPES.CF_SEO_LINKS,
    TYPES.NEW_CAR_LINKS,
    TYPES.CAR_SERVICES,
    TYPES.POPULAR_CAR_LIST_RESOURCES
];
