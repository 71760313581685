/* eslint-disable max-statements */
/* eslint-disable complexity */
import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import { PRIMARY_LINK_HEADER_DATA, TYPES } from "../login-panel/constant";
import useToggle from "../../../hooks/use-toggle";
import LoginPanelPrimaryLinks from "../login-panel-primary-links";
import { LazyComponent } from "../../shared/lazy-image";
import { sellCarLinks } from "../../../constants/tracking-constants/sell-car-link-constants";
import { RESOURCES_LINKING } from "../../../constants/used-car-constants";
import ArrowIcon from "../../shared/arrow";
import { MobileHamburgerDropdown } from "@cars24/turbo-web/lib/mobile-hamburger-dropdown";
import { HeaderParserManager, MobileBuyCarParser, MobileCarServicesParser, MobileCFSeoLinkParse, MobileNewCarsParser, MobileSellCarParser } from "../../../utils/helpers/combined-header-options-parser";
import { CF_SEO_LINKS } from "../../../constants/cf-seo-link-constants";
import { CAR_SERVICE_URLS } from "../../../constants/car-services-constants";
import { appUrl } from "../../../constants/url-constants";

const LoginPanelPrimaryLinksWrapper = ({
    iconsRef,
    iconsLoaded,
    buyCarsHeaderData,
    cityName,
    isOpen
}) => {
    const [isResourcesVisible, setIsResourcesVisible] = useToggle();

    const PRIMARY_LINKS_DATA = {
        [TYPES.POPULAR_CAR_LIST_RESOURCES]: {
            visibilityCheck: isResourcesVisible,
            onClose: setIsResourcesVisible,
            list: RESOURCES_LINKING,
            viewAll: false,
            showLink: true,
            linkClick: setIsResourcesVisible
        }
    };

    const handlePrimaryLinkClick = (type) => {
        PRIMARY_LINKS_DATA[type].linkClick();
    };

    const renderArrow = (visible) => {
        return (
            <LazyComponent>
                <ArrowIcon
                    silver={true}
                    // eslint-disable-next-line no-magic-numbers
                    rotateBy={visible ? -180 : -90}
                    alt={"arrow"}
                />
            </LazyComponent>
        );
    };

    const fetchLocation = () => {
        if (cityName) {
            return cityName;
        } else {
            return "India";
        }
    };

    const handleBuyCarOnlineClick = () => {
        window.location.href = `${appUrl()}/buy-used-cars/`;
    };

    const handleSellCarOnlineClick = () => {
        window.location.href = `${appUrl()}/sell-used-cars/`;
    };

    const HamburgerDropdownTypes = [
        new MobileBuyCarParser({buyCarsData: buyCarsHeaderData, location: fetchLocation(), handleBuyCarOnlineClick, isOpen}),
        new MobileSellCarParser(sellCarLinks, handleSellCarOnlineClick),
        new MobileCFSeoLinkParse(CF_SEO_LINKS),
        new MobileNewCarsParser(isOpen),
        new MobileCarServicesParser(CAR_SERVICE_URLS)
    ];

    const HamburgerDropdownParser = new HeaderParserManager();

    return (
        <div styleName={"styles.hamburgerOptionContainer"}>
            {
                HamburgerDropdownTypes.map((currrentFunction, index) => {
                    return (
                        <MobileHamburgerDropdown key={index} isOpen={false} dropdownData={HamburgerDropdownParser.execute(currrentFunction)} isBoldHeading={true}/>
                    );
                })
            }
            {
                PRIMARY_LINKS_DATA[TYPES.POPULAR_CAR_LIST_RESOURCES] && PRIMARY_LINKS_DATA[TYPES.POPULAR_CAR_LIST_RESOURCES].showLink &&
                        <React.Fragment>
                            <div
                                onClick={() => handlePrimaryLinkClick(TYPES.POPULAR_CAR_LIST_RESOURCES)}
                                className={"d-flex justify-content-between"} styleName={`styles.primaryFont styles.resourcesFont`}>
                                <div styleName={"styles.primaryHeaderWrapper"}>
                                    <span>{iconsLoaded && <img src={iconsRef.current[PRIMARY_LINK_HEADER_DATA[TYPES.POPULAR_CAR_LIST_RESOURCES].icon]} alt="BuyCar" height={"24"} width={"24"} /> }</span>
                                    {PRIMARY_LINK_HEADER_DATA[TYPES.POPULAR_CAR_LIST_RESOURCES].title}
                                </div>
                                {renderArrow(PRIMARY_LINKS_DATA[TYPES.POPULAR_CAR_LIST_RESOURCES].visibilityCheck)}
                            </div>
                            <LoginPanelPrimaryLinks
                                type = {TYPES.POPULAR_CAR_LIST_RESOURCES}
                                {...PRIMARY_LINKS_DATA[TYPES.POPULAR_CAR_LIST_RESOURCES]}
                            />
                        </React.Fragment>

            }
        </div>
    );
};
LoginPanelPrimaryLinksWrapper.propTypes = {
    isLoggedIn: PropTypes.bool,
    userDetailsSubmitted: PropTypes.bool,
    cfForm1Submitted: PropTypes.bool,
    pincodeServiceable: PropTypes.bool,
    bankingSubmitted: PropTypes.bool,
    bookingCount: PropTypes.object,
    isOpen: PropTypes.bool,
    cfStatus: PropTypes.string,
    iconsRef: PropTypes.any,
    iconsLoaded: PropTypes.bool,
    buyCarsHeaderData: PropTypes.object,
    cityName: PropTypes.string
};

export default LoginPanelPrimaryLinksWrapper;
