import { redirect } from "../../../constants/app-constants";
import { hamburgerFAQ, hamburgerPartner, hamburgerRCTransfer } from "./tracking";

export const SECONDARY_ITEMS_LIST = [
    {
        itemTitle: "RC Transfer Status",
        redirect: redirect.RC_TRANSFER,
        gaEvent: hamburgerRCTransfer,
        icon: "RcIcon"
    }, {
        itemTitle: "Become Our Partner",
        redirect: redirect.BECOME_OUR_PARTNER,
        gaEvent: hamburgerPartner,
        icon: "PartnerIcon"
    },
    {
        itemTitle: "Become a CARS24 Franchise",
        redirect: redirect.FRANCHISE,
        gaEvent: null,
        icon: "FranchiseIcon"
    },
    {
        itemTitle: "FAQs",
        redirect: redirect.FAQ,
        gaEvent: hamburgerFAQ,
        icon: "HelpIcon"
    }
];
