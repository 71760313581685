import React from "react";
import loadable from "@loadable/component";
import { Node } from "./step-node";

const LeadBrands = loadable(() => import("../../components/mobile/lead-brands"));
const LeadModels = loadable(() => import("../../components/mobile/lead-models"));
const LeadYears = loadable(() => import("../../components/mobile/lead-years"));
const LeadVariants = loadable(() => import("../../components/mobile/lead-variants"));
const LeadRegistrationStates = loadable(() => import("../../components/mobile/lead-registration-states"));
const LeadBranchDate = loadable(() => import("../../components/mobile/lead-branch-date"));
const LeadBranches = loadable(() => import("../../components/mobile/lead-branch-date"));
const FinancePersonalDetails = loadable(() => import("../../components/mobile/finance-form/personal-details"));
const FinanceResidentialDetails = loadable(() => import("../../components/mobile/finance-form/residential-details"));
const FinanceIncomeDetails = loadable(() => import("../../components/mobile/finance-form/income-details"));
const OdometerReading = loadable(() => import("../../components/mobile/odometer-reading"));
const LeadCity = loadable(() => import("../../components/mobile/lead-city"));

import { STEP_NAMES, LEAD_FORM } from "../../constants/app-constants";
import { createStepper } from "./stepper";

const TABS = [
    { name: "Brand", index: 1, displayName: "Brand", stateName: "brand"},
    { name: "Year", index: 2, displayName: "Year", stateName: "year"},
    { name: "Model", index: 3, displayName: "Model", stateName: "model"},
    { name: "Variant", index: 4, displayName: "Variant", stateName: "variant" },
    { name: "State", index: 5, displayName: "State", stateName: "registrationState"},
    { name: "Kms", index: 6, displayName: "Kms Driven", stateName: "kilometersDriven"},
    { name: "City", index: 7, displayName: "Car location", stateName: "city"}
];

const CONFIRMATION_TAB = {
    name: "Confirmation",
    index: 7
};

const STEPS = [
    new Node({ name: STEP_NAMES.Brand, component: LeadBrands, visited: false }),
    new Node({ name: STEP_NAMES.Year, component: LeadYears, visited: false }),
    new Node({ name: STEP_NAMES.Model, component: LeadModels, visited: false }),
    new Node({ name: STEP_NAMES.Variant, component: LeadVariants, visited: false }),
    new Node({ name: STEP_NAMES.State, component: LeadRegistrationStates, visited: false }),
    new Node({ name: STEP_NAMES.Kms, component: OdometerReading, visited: false }),
    new Node({ name: STEP_NAMES.City, component: LeadCity, visited: false }),
    new Node({ name: STEP_NAMES.Confirmation, component: <div>Confirmation</div>, visited: false })
];

const TABS_APPOINTMENT = [
    { name: "Brand", index: 1 },
    { name: "Model", index: 2 },
    { name: "Branch", index: 3 },
    { name: "Date", index: 4 }
];

const STEPS_APPOINTMENT = [
    new Node({ name: STEP_NAMES.Brand, component: LeadBrands, visited: false }),
    new Node({ name: STEP_NAMES.Model, component: LeadModels, visited: false }),
    new Node({ name: STEP_NAMES.Branch, component: LeadBranches, visited: false }),
    new Node({ name: STEP_NAMES.Date, component: LeadBranchDate, visited: false }),
    new Node({ name: STEP_NAMES.Confirmation, component: <div>Confirmation</div>, visited: false })
];

const TAB_DATA = {
    [STEP_NAMES.Brand]: { displayText: "", id: null },
    [STEP_NAMES.Year]: { displayText: "", id: null, year_id: null },
    [STEP_NAMES.Model]: { displayText: "", id: null },
    [STEP_NAMES.Variant]: { displayText: "", id: null, variantType: null },
    [STEP_NAMES.State]: { displayText: "", id: null },
    [STEP_NAMES.KilometerDriven]: { displayText: "", id: null },
    [STEP_NAMES.City]: { displayText: "", id: null }
};

const TAB_DATA_APPOINTMENT = {
    [STEP_NAMES.Brand]: { displayText: "", id: null },
    [STEP_NAMES.Model]: { displayText: "", id: null },
    [STEP_NAMES.Branch]: { displayText: "", id: null, selectionType: "city" },
    [STEP_NAMES.Date]: { displayText: "", id: null }
};

const FINANCE_FORM_STEPS = [
    new Node({ name: STEP_NAMES.Personal, component: FinancePersonalDetails, visited: false, attributes: {
        serviceName: "FinanceForm"
    } }),
    new Node({ name: STEP_NAMES.Residential, component: FinanceResidentialDetails, visited: false, attributes: {
        serviceName: "FinanceForm"
    } }),
    new Node({ name: STEP_NAMES.Income, component: FinanceIncomeDetails, visited: false, attributes: {
        serviceName: "FinanceForm"
    } }),
    new Node({ name: STEP_NAMES.Confirmation, component: <div>Confirmation</div>, visited: false })
];

const FINANCE_FORM_TABS = [
    { key: "personal", name: "Personal", index: 1 },
    { key: "residential", name: "Residential", index: 2 },
    { key: "income", name: "Income", index: 3 }
];

const FINANCE_FORM_TAB_DATA = {
    [STEP_NAMES.Personal]: { displayText: "", id: null },
    [STEP_NAMES.Residential]: { displayText: "", id: null },
    [STEP_NAMES.Income]: { displayText: "", id: null }
};

const FUNNEL_CONFIG = {
    default: {
        stepper: createStepper({ steps: STEPS }),
        tabs: TABS,
        stepNames: STEP_NAMES,
        flowName: LEAD_FORM.DEFAULT,
        tabData: TAB_DATA
    },
    oneStep: {
        stepper: createStepper({ steps: STEPS_APPOINTMENT }),
        tabs: TABS_APPOINTMENT,
        stepNames: STEP_NAMES,
        flowName: LEAD_FORM.ONE_STEP,
        tabData: TAB_DATA_APPOINTMENT
    },
    financeForm: {
        stepper: createStepper({ steps: FINANCE_FORM_STEPS }),
        tabs: FINANCE_FORM_TABS,
        stepNames: STEP_NAMES,
        flowName: LEAD_FORM.FINANCE_FORM,
        tabData: FINANCE_FORM_TAB_DATA
    }
};

export {
    STEPS,
    STEP_NAMES,
    TABS,
    CONFIRMATION_TAB,
    TABS_APPOINTMENT,
    STEPS_APPOINTMENT,
    FUNNEL_CONFIG,
    TAB_DATA,
    TAB_DATA_APPOINTMENT,
    FINANCE_FORM_STEPS,
    FINANCE_FORM_TABS,
    FINANCE_FORM_TAB_DATA
};
