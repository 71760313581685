import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import { CF_SEO_LINKS } from "../../../constants/cf-seo-link-constants";

const CfSEOLinks = () => {

    return (
        <React.Fragment>
            <ul styleName={"styles.collapse styles.sellCar"}>
                {
                    (CF_SEO_LINKS || []).map((item, i) => (
                        <li key={i} styleName={"styles.cardTitle"}>
                            <a href={item.url} target="_blank">
                                {item.text}
                            </a>
                        </li>
                    ))
                }
            </ul>

        </React.Fragment>
    );
};

CfSEOLinks.propTypes = {
    onClose: PropTypes.func,
    heading: PropTypes.string
};

export default CfSEOLinks;
