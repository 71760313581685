import Types from "./types";

import { CatalogGatewayService } from "../../../service/catalog-gateway";

const getFeaturedPopularCities = (cities) => {
    return (cities && cities.length) ? cities.filter((make) => make.featured) : [];
};

const fetchCityListAndBrandsInit = () => ({
    type: Types.FETCH_POPULAR_CITY_LIST_AND_BRANDS
});

const fetchCityListAndBrandsSuccess = (data) => ({
    type: Types.FETCH_POPULAR_CITY_LIST_AND_BRANDS_SUCCESS,
    data
});

const fetchCityListAndBrandsFailure = (error) => ({ type: Types.FETCH_POPULAR_CITY_LIST_AND_BRANDS_FAILURE, error });

const fetchCityListAndBrands = () => (dispatch) => {
    dispatch(fetchCityListAndBrandsInit());
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getPopularCityListAndBrands()
            .then(response => {
                dispatch(fetchCityListAndBrandsSuccess(getFeaturedPopularCities(response.data)));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchCityListAndBrandsFailure(error));
                reject(error);
            });
    });
};

const updatePopularCityListSSRStatus = (isSSR) => ({
    type: Types.UPDATE_POPULAR_CITY_LIST_SSR_STATUS,
    isSSR
});

export {
    fetchCityListAndBrands,
    updatePopularCityListSSRStatus
};
