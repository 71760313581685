import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const Accordion = ({
    children,
    totalItems = 1,
    firstDefaultOpen = false,
    toggleAll = false
}) => {
    const generateArray = () => Array(...new Array(totalItems)).map(() => false);

    let itemsArray = firstDefaultOpen ?
        Array(...new Array(totalItems)).map((_, index) => index === 0 ? true : false)
        :
        generateArray();

    const [currentVisibleStates, setCurrentVisibleStates] = useState(
        itemsArray
    );

    useEffect(() => {
        if (itemsArray) {
            setCurrentVisibleStates(itemsArray);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstDefaultOpen]);

    const onAccordionClickHandler = ({ index }) => {
        // reseting all index to false for firstDefaultOpen is  true //
        if (firstDefaultOpen) {
            itemsArray = generateArray();
        }
        const currentState = !toggleAll ? [...itemsArray] : [...currentVisibleStates];
        currentState[index] = !currentVisibleStates[index];
        setCurrentVisibleStates(currentState);
    };

    return (
        <ul>
            {children({
                onClick: onAccordionClickHandler,
                currentVisibleStates
            })}
        </ul>
    );
};

Accordion.propTypes = {
    children: PropTypes.object,
    totalItems: PropTypes.number,
    toggleAll: PropTypes.bool,
    firstDefaultOpen: PropTypes.bool
};

const Heading = ({ children, headingRef }) => {
    return (
        <React.Fragment>
            {React.cloneElement(children, { ref: headingRef })}
        </React.Fragment>
    );
};
Heading.propTypes = {
    children: PropTypes.object,
    headingRef: PropTypes.object
};

const Body = ({ children }) => {
    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};
Body.propTypes = {
    children: PropTypes.object
};
const Content = ({
    children
}) => {
    return (
        <li>
            {children}
        </li>
    );
};
Content.propTypes = {
    children: PropTypes.object
};

Accordion.Content = Content;
Accordion.Heading = Heading;
Accordion.Body = Body;

export default Accordion;
