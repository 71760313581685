import React from "react";
import { CAR_SERVICE_URLS } from "../../../constants/car-services-constants";
import { appUrl } from "../../../constants/url-constants";
import styles from "./styles.css";
import { sendCustomGaEvents } from "../../../tracking";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";

const CarServices = () => {

    const sendListGaEvents = async (eventObject) => {
        await yieldToMain();
        sendCustomGaEvents(eventObject);
    };

    // eslint-disable-next-line max-params
    const listItem = (text, url, eventObject = {}, customUrl = "") => {
        return (
            <a href={customUrl ? customUrl : `${appUrl()}/${url}`} onClick={() => {
                if (eventObject) {
                    sendListGaEvents(eventObject);
                }
            }} target="_blank">
                {text}
            </a>
        );
    };

    return (
        <React.Fragment>
            <ul styleName={"styles.collapse styles.sellCar"}>
                {
                    (CAR_SERVICE_URLS || []).map((item, i) => (
                        <li key={i} styleName={"styles.cardTitle"}>
                            {listItem(item.title, item.seoLink, item.event, item.customUrl)}
                        </li>
                    ))
                }
            </ul>

        </React.Fragment>
    );
};

export default CarServices;
