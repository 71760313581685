import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LoginPanel from "./component";
// import withOtp from "../../shared/with-otp";
import { fetchCityListAndBrands, updatePopularCityListSSRStatus } from "../popular-car-list/actions";
import { fetchBuyCarsHeaderData, updateSSRStatus } from "./actions";

const mapStateToProps = ({
    user: {
        mobile,
        email
    },
    popularCarList: { isPopularCityListSSR, popularCityListsAndAvailableBrands },
    cities: { selectedCity: { id: cityId } },
    loginPanel: { isSSR: loginPanelSSR }
}) => ({
    mobile,
    isPopularCityListSSR,
    email,
    popularCityListsAndAvailableBrands,
    cityId,
    loginPanelSSR
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updatePopularCityListSSRStatusConnect: updatePopularCityListSSRStatus,
    fetchCityListAndBrandsConnect: fetchCityListAndBrands,
    fetchBuyCarsHeaderDataConnect: fetchBuyCarsHeaderData,
    updateSSRStatusConnect: updateSSRStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoginPanel);
