import React from "react";
import PropTypes from "prop-types";
import PopularCarList from "../popular-car-list";
import { TYPES } from "../login-panel/constant";
import CfSEOLinks from "../cf-seo-links";
import NewCarsLinks from "../new-cars-links";
import CarServices from "../car-services";
import styles from "./styles.css";

const LoginPanelPrimaryLinks = ({
    type = "",
    onClose = () => {},
    pathname = "",
    ctaText = "",
    visibilityCheck = false,
    list = [],
    viewAll = false
}) => {

    const renderLayout = () => {

        if (type === TYPES.CF_SEO_LINKS) {
            return (<CfSEOLinks />);
        }

        if (type === TYPES.NEW_CAR_LINKS) {
            return (<NewCarsLinks
                onClose={onClose}
            />);
        }

        if (type === TYPES.CAR_SERVICES) {
            return (<CarServices />);
        }

        return (
            <PopularCarList
                onClose={onClose}
                pathname={pathname}
                ctaText={ctaText}
                isPopularCarsVisible={visibilityCheck}
                list={list}
                viewAll={viewAll}
            />
        );
    };

    return (
        <div styleName={`styles.WrapperPopular`}>
            <div styleName={visibilityCheck ? "styles.showLinks" : "styles.hideLinks"}>
                {renderLayout()}
            </div>
        </div>
    );
};
LoginPanelPrimaryLinks.propTypes = {
    type: PropTypes.string,
    onClose: PropTypes.func,
    pathname: PropTypes.string,
    ctaText: PropTypes.string,
    visibilityCheck: PropTypes.bool,
    list: PropTypes.array,
    viewAll: PropTypes.bool
};

export default LoginPanelPrimaryLinks;
