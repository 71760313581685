import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import { sendCustomGaEvents } from "../../../tracking";
import { SECONDARY_ITEMS_LIST } from "./constant";
// import LazyImage from "../../shared/lazy-image";

const LoginPanelSecondaryItems = ({
    iconsRef,
    iconsLoaded
}) => {

    const sendListGaEvents = async (eventObject) => {
        await yieldToMain();
        sendCustomGaEvents(eventObject);
    };

    // eslint-disable-next-line max-params
    const listItem = (text, url, eventObject = {}, onClickHandler) => {
        return (
            <a href={url} onClick={(e) => {
                if (onClickHandler) {
                    e.preventDefault();
                    onClickHandler();
                } else {
                    sendListGaEvents(eventObject);
                }
            }}>
                {text}
            </a>
        );
    };

    return (
        <React.Fragment>
            {
                SECONDARY_ITEMS_LIST.map(({itemTitle, redirect, gaEvent, icon}, index) => (
                    <div key={`secondary-item-${index}`} styleName={"styles.secondaryListStyle"}>
                        <div styleName={"styles.secondaryHeaderWrapper"}>
                            <span>{iconsLoaded && <img src={iconsRef.current[icon]} alt="RcIcon" width={24} height={24} />}</span>
                            {listItem(itemTitle, redirect, gaEvent)}
                        </div>
                    </div>
                ))
            }
        </React.Fragment>
    );
};

LoginPanelSecondaryItems.propTypes = {
    iconsRef: PropTypes.any,
    iconsLoaded: PropTypes.bool
};

export default LoginPanelSecondaryItems;

