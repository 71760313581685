import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LoginPanelPrimaryLinksWrapper from "./component";

const mapStateToProps = ({
    user: {
        isLoggedIn,
        userDetailsSubmitted,
        pincodeServiceable,
        bankingSubmitted,
        bookingCount
    },
    consumerFinance: {
        cfStatus,
        cfForm1Submitted
    },
    loginPanel: { buyCarsHeaderData },
    cities: { selectedCity: { name: cityName } }
}) => ({
    isLoggedIn,
    userDetailsSubmitted,
    cfStatus,
    cfForm1Submitted,
    pincodeServiceable,
    bankingSubmitted,
    bookingCount,
    buyCarsHeaderData,
    cityName
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoginPanelPrimaryLinksWrapper);
