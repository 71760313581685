import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_CAR_DETAIL_BY_CAR_ID
    FETCH_CAR_DETAIL_BY_CAR_ID_SUCCESS
    FETCH_CAR_DETAIL_BY_CAR_ID_FAILURE

    UPDATE_SSR_STATUS

    RESET_BEST_MATCHES_RAIL_DATA
`,
    {
        prefix: "bestMatchesRail/"
    }
);
