/* eslint-disable no-magic-numbers */
import React from "react";
import styles from "./style.css";
import { MODAL_USER_ACTION } from "../../../constants/app-constants";
import PropTypes from "prop-types";
import { getOtherAnchorURL, getAllCity } from "../../../utils/helpers/get-sell-anchor-url";
import { appUrl } from "../../../constants/url-constants";
import { buyCarLinks } from "../../../constants/tracking-constants/buy-car-link-constants";

const PopularCarList = ({
    pathname,
    viewAll = true,
    list,
    ctaText = ""
}) => {

    return (
        <React.Fragment>
            {pathname === MODAL_USER_ACTION.BUY ?
                <ul styleName={"styles.collapse styles.sellCar"}>
                    {buyCarLinks.map((item, i) => {
                        return (
                            <li key={i} styleName={"styles.cardTitle"}>
                                <a href={`${appUrl()}/${item.seoLink}`} >
                                    {`Used car in ${item.title}`}
                                </a>
                            </li>
                        );
                    })}
                </ul>
                : <ul styleName={"styles.collapse styles.sellCar"}>
                    { pathname === MODAL_USER_ACTION.SELL &&
                        <li key={"used-car-valuation"} styleName={"styles.cardTitle"}>
                            <a href={getOtherAnchorURL("used-car-valuation")} >
                                Used Car Valuation
                            </a>
                        </li>
                    }
                    {
                        list.map((item, i) => (
                            <li key={i} styleName={"styles.cardTitle"}>
                                {pathname === MODAL_USER_ACTION.SELL ?
                                    <a href={`${appUrl()}/${item.seoLink}`} >
                                        {`Sell Car in ${item.title}`}
                                        {/* <span styleName={"styles.cityName"}>{item.displayTextSell}</span> */}
                                    </a>
                                    : <a href={getOtherAnchorURL(item.value, pathname)} >
                                        {item.displayText}
                                    </a>
                                }
                            </li>
                        ))
                    }
                </ul>}

            <div styleName={"styles.allCityCta"}>
                {
                    viewAll &&
                    <a href={getAllCity(pathname)}>
                        {ctaText}
                    </a>
                }
            </div>
        </React.Fragment>
    );
};

PopularCarList.propTypes = {
    pathname: PropTypes.string,
    list: PropTypes.array,
    viewAll: PropTypes.bool,
    ctaText: PropTypes.string
};

export default PopularCarList;
