export const hamburgerRCTransfer = {
    event: "ls_RC_transfer_burger_menu",
    eventCategory: "homepage",
    eventAction: "burger_menu",
    eventLabel: "rcTransfer"
};

export const hamburgerPartner = {
    event: "ls_become_our_partner_burger_menu",
    eventCategory: "homepage",
    eventAction: "burger_menu",
    eventLabel: "becomeOurPartner"
};

export const hamburgerFAQ = {
    event: "ls_FAQs_burger_menu",
    eventCategory: "homepage",
    eventAction: "burger_menu",
    eventLabel: "FAQs"
};
