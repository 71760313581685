import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PopularCarList from "./component";

const mapStateToProps = ({
    cities: {
        selectedCity
    },
    popularCarList: {
        popularCityListsAndAvailableBrands
    }
}) => ({
    selectedCity,
    popularCityListsAndAvailableBrands
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PopularCarList);
