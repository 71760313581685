/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import styles from "./styles.css";
import CloseButton from "../../shared/icon-cross";
import { redirect, NUMBER } from "../../../constants/app-constants";
import Logo from "../../shared/logo";
import LazyImage from "../../shared/lazy-image";
import { LazyComponent } from "../../shared/lazy-image";
import LoginPanelSecondaryItems from "../../mobile/login-panel-secondary-items";
import LoginPanelPrimaryLinksWrapper from "../login-panel-primary-links-wrapper";
import LoginPanelCta from "../login-panel-cta";
const LoginPanelUserActionables = loadable(() => import("../login-panel-user-actionables"));

const LoginPanel = ({
    close,
    userName,
    isLoggedIn,
    isOpen = false,
    isPopularCityListSSR,
    fetchCityListAndBrandsConnect,
    updatePopularCityListSSRStatusConnect,
    email,
    mobile,
    popularCityListsAndAvailableBrands,
    cityId,
    fetchBuyCarsHeaderDataConnect,
    updateSSRStatusConnect,
    loginPanelSSR
}) => {
    // eslint-disable-next-line no-unused-vars
    const [iconsLoaded, setIconsLoaded] = useState(false);
    const iconsRef = useRef({});

    const hideSidebar = () => {
        window.setTimeout(close, NUMBER.TWO_HUNDRED);
    };

    useEffect(() => {
        if (!loginPanelSSR) {
            fetchBuyCarsHeaderDataConnect(cityId);
        } else {
            updateSSRStatusConnect(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId]);

    useEffect(() => {
        if (isOpen && Object.keys(iconsRef.current).length === 0 && !iconsLoaded) {
            import(/* webpackChunkName: "login-panel-icons" */ "./icons").then(res => {
                iconsRef.current = res.default();
                setIconsLoaded(true);
            });
            // console.warn("iconsRef.current", iconsRef.current);
        }
    }, [iconsLoaded, isOpen]);

    const onEditProfileClick = () => {
        window.location.href = redirect.AUTH_PROFILE;
    };

    useEffect(() => {
        if (!isPopularCityListSSR && (popularCityListsAndAvailableBrands || []).length === NUMBER.ZERO) {
            fetchCityListAndBrandsConnect();
        } else {
            updatePopularCityListSSRStatusConnect(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div styleName={`styles.wrap ${isOpen ? "styles.slideIn" : "styles.slideOut"}`}>
            <div styleName={"styles.whiteBg styles.header"}>
                <div className={"container"} styleName={"styles.login"}>
                    <Logo width={61.3} height={29.2} />
                    <span onClick={hideSidebar}>
                        <LazyComponent>
                            <CloseButton type="black" height={"24"} width={"24"} />
                        </LazyComponent>
                    </span>
                </div>
            </div>
            {
                isLoggedIn &&
                <div styleName={"styles.userLogin"}>
                    <div styleName={"styles.loginText"}>
                        <h3>Hi, {userName || "Customer"}</h3>
                        {
                            mobile &&
                            <p>+91 {mobile}</p>
                        }
                        {
                            email &&
                            <p>{email}</p>
                        }
                    </div>
                    <LazyImage src={iconsRef.current.ProfileEdit} alt="edit" onClick={onEditProfileClick} width={18} height={18} />
                </div>
            }
            <div className={"container"} styleName={`styles.whiteBg styles.p-20 ${isLoggedIn ? "" : "styles.containerMr"}`}>
                <div styleName={"styles.overlay"}>
                    {isLoggedIn &&
                    <div styleName={"styles.userActionables"}>
                        <LazyComponent>
                            <LoginPanelUserActionables
                                isOpen={isOpen}
                                iconsRef={iconsRef}
                                iconsLoaded={iconsLoaded}
                            />
                        </LazyComponent>
                    </div>
                    }
                    <LoginPanelPrimaryLinksWrapper iconsRef={iconsRef} iconsLoaded={iconsLoaded} isOpen={isOpen}/>
                    <LoginPanelSecondaryItems iconsRef={iconsRef} iconsLoaded={iconsLoaded}/>
                </div>
            </div>
            <LoginPanelCta logoutIcon={iconsRef.current.LogoutIcon}/>
        </div>
    );
};
LoginPanel.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    userName: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    mobile: PropTypes.string,
    isPopularCityListSSR: PropTypes.bool,
    fetchCityListAndBrandsConnect: PropTypes.func,
    updatePopularCityListSSRStatusConnect: PropTypes.func,
    logoutUser: PropTypes.func,
    email: PropTypes.string,
    popularCityListsAndAvailableBrands: PropTypes.array,
    logoutConnect: PropTypes.func,
    markUnauthorizedConnect: PropTypes.func,
    cityId: PropTypes.string,
    fetchBuyCarsHeaderDataConnect: PropTypes.func,
    updateSSRStatusConnect: PropTypes.func,
    loginPanelSSR: PropTypes.bool
};
export default LoginPanel;
